export const resizeImage = (file) => {
  const maxHeight = 200;
  const maxWidth = 200;

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = maxWidth;
        canvas.height = maxHeight;
        const ctx = canvas.getContext("2d");

        let height = maxHeight,
          width = maxWidth,
          x = 0,
          y = 0;

        if (img.width > img.height) {
          width = (img.width * maxWidth) / img.height;
          x = -((width - maxWidth) / 2);
        } else if (img.height > img.width) {
          height = (img.height * maxHeight) / img.height;
          y = -((height - maxHeight) / 2);
        }

        ctx.drawImage(img, x, y, width, height);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: "image/jpeg",
              });
              resolve(resizedFile);
            } else {
              reject(new Error("Erreur lors de la création du blob"));
            }
          },
          "image/jpeg",
          0.9, // Qualité de l'image
        );
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  });
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
