import PropTypes from "prop-types";
import React, { useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Avatar, Box, IconButton, Link } from "@material-ui/core";
import {
  AccountCircle as AccountCircleIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Mail as MailIcon,
  NoAccounts as DisableIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import { useQuery } from "react-query";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { getImpersonateUser, getUsersAll } from "../../http/user";
import renderCellExpand from "../../utils/renderCellExpand";
import AppCircularProgress from "../AppCircularProgress";
import GuardedComponent from "../GuardedComponent";
import UserDeleteModal from "./UserDeleteModal";
import UserResetPasswordModal from "./UserResetPasswordModal";
import checkAccessStore from "../../utils/checkAccessStore";
import homePageList from "../../router/HomePageList";
import { connect } from "react-redux";
import UserDisableModal from "./UserDisableModal";

const HubnupUsersList = ({
  filters,
  isFetchedProfiles,
  profiles,
  isImpersonateUser,
}) => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [sortModel, setSortModel] = useState([
    { field: "profile", sort: "asc" },
  ]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [impersonateUserId, setImpersonateUserId] = useState(false);

  const { isFetching, data: usersData } = useQuery(
    ["getUsersAll", filters, page, sortModel],
    async () => {
      const data = await getUsersAll(filters, page, sortModel);

      const users = data["hydra:member"];
      users.map((user) => {
        const profile = profiles.find(
          (profile) => profile.name === user.roles[0],
        );
        if (!profile) {
          user.profile = "";

          return user;
        }
        user.profile = profile.title;

        return user;
      });

      return {
        users: users,
        totalItems: data["hydra:totalItems"],
      };
    },
    {
      enabled: !!profiles,
      keepPreviousData: true,
    },
  );

  useQuery(
    ["getImpersonateUser", impersonateUserId],
    async () => {
      const data = await getImpersonateUser(impersonateUserId);

      let homePage = null;
      const item = data.components?.find(
        (component) =>
          component.search("_page") && homePageList.hasOwnProperty(component),
      );
      if (item) {
        homePage = homePageList[item];
      }

      history.push(homePage ?? "/");

      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!impersonateUserId,
    },
  );

  const handleResetPassword = (user) => {
    setOpenResetPasswordModal(true);
    setSelectedUser(user);
  };

  const handleCloseResetPasswordModal = () => {
    setOpenResetPasswordModal(false);
    setSelectedUser(null);
  };

  const handleDelete = (user) => {
    setOpenDeleteModal(true);
    setSelectedUser(user);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedUser(null);
  };

  const handleDisable = (user) => {
    setOpenDisableModal(true);
    setSelectedUser(user);
  };

  const handleCloseDisableModal = () => {
    setOpenDisableModal(false);
    setSelectedUser(null);
  };

  const handleImpersonateUser = (userId) => {
    setImpersonateUserId(userId);
  };

  const renderImpersonateLink = (params) => {
    return renderCellExpand(
      params,
      true,
      !isImpersonateUser && checkAccessStore("users_switch_user") ? (
        <Link
          onClick={() => handleImpersonateUser(params.id)}
          sx={{ cursor: "pointer" }}
        >
          {params.value}
        </Link>
      ) : (
        params.value
      ),
    );
  };

  const columns = [
    {
      field: "picture",
      headerName: " ",
      sortable: false,
      width: 60,
      renderCell: (params) =>
        params.value ? (
          <Avatar
            alt={params.row.lastName}
            src={process.env.REACT_APP_API_URL + params.value}
          />
        ) : (
          <Avatar>
            <AccountCircleIcon />
          </Avatar>
        ),
    },
    {
      field: "email",
      headerName: "Mail (Login)",
      flex: 2,
      renderCell: renderImpersonateLink,
    },
    {
      field: "lastName",
      headerName: "Nom",
      flex: 1,
      renderCell: renderImpersonateLink,
    },
    {
      field: "firstName",
      headerName: "Prénom",
      flex: 1,
      renderCell: renderImpersonateLink,
    },
    {
      field: "profile",
      headerName: "Profil",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "cgpId",
      headerName: "ID CGP",
      sortable: false,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "passwordActions",
      headerName: "Mot de passe",
      sortable: false,
      width: 120,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <IconButton
            aria-label="Réinitialiser le mot de passe"
            size="small"
            onClick={() => handleResetPassword(params.row)}
          >
            <MailIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: " ",
      sortable: false,
      width: 180,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <GuardedComponent componentName="users_details_page">
            <IconButton
              aria-label="Voir le rappel"
              size="small"
              component={RouterLink}
              to={{
                pathname: `/administration/users/${params.id}`,
                state: { goBack: history.location.pathname },
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </GuardedComponent>
          <GuardedComponent componentName="users_edit_page">
            <IconButton
              aria-label="Modifier le rappel"
              size="small"
              component={RouterLink}
              to={{
                pathname: `/administration/users/${params.id}/edit`,
                state: { goBack: history.location.pathname },
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="Désactiver le rappel"
              size="small"
              onClick={() => handleDisable(params.row)}
            >
              <DisableIcon />
            </IconButton>
            <IconButton
              aria-label="Supprimer le rappel"
              size="small"
              onClick={() => handleDelete(params.row)}
            >
              <DeleteIcon />
            </IconButton>
          </GuardedComponent>
        </Box>
      ),
    },
  ];

  const handleSortModelChange = (params) => {
    if (params.sortModel !== sortModel) {
      setSortModel(params.sortModel);
    }
  };

  return (
    <>
      {isFetchedProfiles && usersData ? (
        <DataGrid
          loading={isFetching}
          rows={usersData.users}
          columns={columns}
          sortModel={sortModel}
          pageSize={10}
          autoHeight
          disableColumnFilter
          disableSelectionOnClick
          disableColumnMenu
          disableColumnReorder
          pagination
          paginationMode="server"
          rowCount={usersData.totalItems}
          onPageChange={(params) => setPage(params.page)}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
        />
      ) : (
        <AppCircularProgress />
      )}

      <UserResetPasswordModal
        open={openResetPasswordModal}
        onClose={handleCloseResetPasswordModal}
        user={selectedUser}
      />
      <UserDisableModal
        user={selectedUser}
        onClose={handleCloseDisableModal}
        open={openDisableModal}
      />
      <UserDeleteModal
        user={selectedUser}
        onClose={handleCloseDeleteModal}
        open={openDeleteModal}
      />
    </>
  );
};

HubnupUsersList.propTypes = {
  filters: PropTypes.object,
  isFetchedProfiles: PropTypes.bool,
  profiles: PropTypes.array,
};

const mapStateToProps = (state) => ({
  isImpersonateUser: state.auth.isImpersonateUser,
});

export default connect(mapStateToProps, null)(HubnupUsersList);
