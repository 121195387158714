import FormHelperText from "@material-ui/core/FormHelperText";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@material-ui/lab";
import {
  AccountCircle as AccountCircleIcon,
  Check as CheckIcon,
  Create as CreateIcon,
  Delete as DeleteIcon,
  Mail as MailIcon,
  NoAccounts as DisableIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { generateUniqueID } from "web-vitals/dist/modules/lib/generateUniqueID";
import * as yup from "yup";
import { getAgentsCGP } from "../../http/agents";
import { postUser, updatePicture } from "../../http/user";
import AppCircularProgress from "../AppCircularProgress";
import UserDeleteModal from "./UserDeleteModal";
import UserResetPasswordModal from "./UserResetPasswordModal";
import UserDisableModal from "./UserDisableModal";
import { convertBase64, resizeImage } from "../../utils/componentHelpers/image";

const HubnupUsersFormContent = ({ user, profiles }) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [pictureFile, setPictureFile] = useState(null);
  const profilesValue = profiles ? profiles.map((profile) => profile.name) : [];
  profilesValue.push("");

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Ce champ est obligatoire.")
      .email("Cette valeur n'est pas une adresse email valide."),
    lastName: yup.string().required("Ce champ est obligatoire."),
    firstName: yup.string().required("Ce champ est obligatoire."),
    profile: yup
      .string()
      .required("Ce champ est obligatoire.")
      .oneOf(profilesValue),
    cgpId: yup.string().when("profile", {
      is: (val) => val && val.includes("ROLE_MANDATAIRE"),
      then: yup.string().required("Ce champ est obligatoire."),
    }),
  });

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      email: user?.email || "",
      lastName: user?.lastName || "",
      firstName: user?.firstName || "",
      profile: user?.profile || "",
      cgpId: user?.cgpId || "",
    },
    resolver: yupResolver(schema),
  });

  const email = watch("email");
  const lastName = watch("lastName");
  const firstName = watch("firstName");
  const watchProfile = watch("profile");

  const { isFetching: isFetchingCGP, refetch } = useQuery(
    ["getAgentsCGP"],
    async () => {
      const data = await getAgentsCGP(email, lastName, firstName);
      if (data.length === 0) {
        enqueueSnackbar("L'utilisateur n'a pas été trouvé.", {
          variant: "error",
        });
      }
      const agent = data[0];
      setValue("cgpId", agent.idCgp);

      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  const goBack = () => {
    if (history.location.state) {
      history.go(history.location.state.goBack);
    }
    history.goBack();
  };

  const onSubmit = async (data) => {
    const postData = {
      ...data,
      roles: [data.profile],
      user,
    };

    if (!user) {
      postData.password = generateUniqueID();
    }

    editUserMutation.mutate(postData);
  };

  const editUserMutation = useMutation({
    mutationKey: ["create-or-update-user"],
    mutationFn: postUser,
    onSuccess: async (createdUser) => {
      if (pictureFile) {
        const formData = new FormData();

        formData.append("file", pictureFile);
        formData.append("circularPicture", createdUser.circularPicture);

        await updatePicture({ userId: createdUser.id, formData }).catch(
          console.info,
        );
      }

      if (user) {
        enqueueSnackbar("L'utilisateur a bien été modifié.", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("L'utilisateur a bien été créé.", {
          variant: "success",
        });
      }

      goBack();
    },
  });

  const handleSearchCGP = async () => {
    await refetch();
  };

  const [displayFile, setDisplayFile] = useState(
    user?.picture ? process.env.REACT_APP_API_URL + user.picture : null,
  );

  const uploadImage = async (e) => {
    const file = e.target.files[0];

    resizeImage(file).then((resizeImage) => {
      convertBase64(resizeImage).then(setDisplayFile);
      setPictureFile(resizeImage);
    });
  };

  return (
    <>
      <CardContent>
        <form
          noValidate
          id="edit-form"
          key="edit-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="email"
                control={control}
                shouldUnregister
                render={({ field, formState: { errors } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Mail (login)"
                    margin="normal"
                    type="email"
                    error={Boolean(errors.email?.message)}
                    helperText={errors.email?.message}
                  />
                )}
              />
              {user && (
                <Button
                  fullWidth
                  margin="normal"
                  variant="contained"
                  startIcon={<MailIcon />}
                  sx={{ marginTop: "18px", marginBottom: "8px" }}
                  onClick={() => setOpenResetPasswordModal(true)}
                >
                  Réinitialiser le mot de passe
                </Button>
              )}
              <Controller
                name="lastName"
                control={control}
                shouldUnregister
                render={({ field, formState: { errors } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Nom"
                    margin="normal"
                    error={Boolean(errors.lastName?.message)}
                    helperText={errors.lastName?.message}
                  />
                )}
              />
              <Controller
                name="firstName"
                control={control}
                shouldUnregister
                render={({ field, formState: { errors } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Prénom"
                    margin="normal"
                    error={Boolean(errors.firstName?.message)}
                    helperText={errors.firstName?.message}
                  />
                )}
              />
              <Controller
                name="profile"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={errors.profile?.message}
                  >
                    <InputLabel id="users-profile-label">Profil</InputLabel>
                    <Select
                      {...field}
                      label="Profil"
                      labelId="users-profile-label"
                    >
                      <MenuItem value="">
                        <em>Tous</em>
                      </MenuItem>
                      {profiles ? (
                        profiles.map((profile) => (
                          <MenuItem value={profile.name} key={profile.name}>
                            {profile.title}
                          </MenuItem>
                        ))
                      ) : (
                        <AppCircularProgress />
                      )}
                    </Select>
                    <FormHelperText>{errors.profile?.message}</FormHelperText>
                  </FormControl>
                )}
              />
              {watchProfile.includes("ROLE_MANDATAIRE") && (
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Controller
                      name="cgpId"
                      control={control}
                      shouldUnregister
                      render={({ field, formState: { errors } }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="ID CGP"
                          margin="normal"
                          disabled
                          error={Boolean(errors.cgpId?.message)}
                          helperText={errors.cgpId?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{ alignSelf: "center" }}>
                    <LoadingButton
                      fullWidth
                      variant="contained"
                      startIcon={<SearchIcon />}
                      onClick={handleSearchCGP}
                      loading={isFetchingCGP}
                    >
                      Rechercher ID CGP
                    </LoadingButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item sm={1} />
            <Grid item xs={12} sm={5}>
              <Typography>Photo : </Typography>
              <Box className={classes.imageContainer}>
                <Avatar sx={{ width: 200, height: 200 }}>
                  {displayFile ? (
                    <img alt="preview" src={displayFile} />
                  ) : (
                    <AccountCircleIcon sx={{ fontSize: 200 }} />
                  )}
                </Avatar>

                <Button
                  startIcon={<CreateIcon />}
                  className={classes.pictureIcon}
                  component="label"
                >
                  <input type="file" hidden onChange={uploadImage} />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <Divider />
      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        spacing={2}
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="outlined" onClick={goBack}>
          Annuler
        </Button>
        {user && (
          <>
            <Button
              variant="contained"
              color="hubnup"
              startIcon={<DeleteIcon />}
              onClick={() => setOpenDeleteModal(true)}
            >
              Supprimer
            </Button>
          </>
        )}

        {user && (
          <Button
            variant="contained"
            color="hubnup"
            startIcon={<DisableIcon />}
            onClick={() => setOpenDisableModal(true)}
          >
            Désactiver
          </Button>
        )}

        <LoadingButton
          loading={editUserMutation.isLoading}
          variant="contained"
          type="submit"
          form="edit-form"
          startIcon={<CheckIcon />}
        >
          Valider
        </LoadingButton>

        {openDeleteModal && (
          <UserDeleteModal
            user={user}
            onClose={() => setOpenDeleteModal(false)}
            open={openDeleteModal}
            goBack={goBack}
          />
        )}

        {openDisableModal && (
          <UserDisableModal
            user={user}
            onClose={() => setOpenDisableModal(false)}
            open={openDisableModal}
            goBack={goBack}
          />
        )}

        {openResetPasswordModal && (
          <UserResetPasswordModal
            open={openResetPasswordModal}
            onClose={() => setOpenResetPasswordModal(false)}
            user={user}
          />
        )}
      </Stack>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  pictureIcon: {
    position: "absolute",
    bottom: "0",
    left: "180px",

    "&:hover": {
      backgroundColor: "inherit",
    },
    "&:focus": {
      backgroundColor: "inherit",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
  },
  imageContainer: {
    position: "relative",
  },
}));

HubnupUsersFormContent.propTypes = {
  user: PropTypes.object,
  profiles: PropTypes.array,
};

export default HubnupUsersFormContent;
