import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
} from "@material-ui/core";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import GuardedComponent from "../../components/GuardedComponent";
import AgentUserFormAvatar from "../../components/user/AgentUserFormAvatar";
import AgentUserFormEmail from "../../components/user/AgentUserFormEmail";
import AgentUserFormPassword from "../../components/user/AgentUserFormPassword";
import AgentUserFormRequest from "../../components/user/AgentUserFormRequest";
import { getCurrentUser } from "../../http/user";
import AppCircularProgress from "../../components/AppCircularProgress";

const AgentUserEdit = () => {
  const history = useHistory();
  const { isFetched, data: user } = useQuery(["getCurrentUser"], () => {
    return getCurrentUser();
  });

  const goBack = () => {
    if (history.location.state) {
      history.go(history.location.state.goBack);
    }
    history.goBack();
  };

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />

      <Box>
        <Grid container spacing={2} alignItems="stretch">
          <GuardedComponent componentName="account_modifications_connexion">
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Connexion" />
                <Divider />
                <CardContent>
                  <AgentUserFormEmail user={user} isFetched={isFetched} />
                  <Divider sx={{ marginTop: "8px" }} />
                  <AgentUserFormPassword user={user} isFetched={isFetched} />
                </CardContent>
              </Card>
            </Grid>
          </GuardedComponent>
          <GuardedComponent componentName="account_modifications_informations">
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Autres informations" />
                <Divider />
                <CardContent>
                  {isFetched ? (
                    <AgentUserFormAvatar user={user} />
                  ) : (
                    <AppCircularProgress />
                  )}
                  <Divider sx={{ marginTop: "16px" }} />
                  <AgentUserFormRequest />
                </CardContent>
              </Card>
            </Grid>
          </GuardedComponent>
        </Grid>
      </Box>

      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        spacing={2}
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="outlined" onClick={goBack}>
          Retour
        </Button>
      </Stack>
    </Container>
  );
};

export default AgentUserEdit;
