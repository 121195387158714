import {
  AccountBox,
  AccountCircle as AccountCircleIcon,
  Create as CreateIcon,
} from "@material-ui/icons";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import {
  Alert,
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";
import { updatePicture } from "../../http/user";
import { convertBase64, resizeImage } from "../../utils/componentHelpers/image";

const AgentUserFormAvatar = ({ user }) => {
  const classes = useStyles();

  const [file, setFile] = useState(null);
  const [circular, setCircular] = useState(true);

  const [displayFile, setDisplayFile] = useState(
    user?.picture ? process.env.REACT_APP_API_URL + user.picture : null,
  );

  const { handleSubmit } = useForm();

  const track = useTrackingContext();

  const onSubmit = async () => {
    track.click(ACTION_CLICK.EDIT_MY_PICTURE);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("circularPicture", circular);

    updatePictureMutation.mutate({ userId: user.id, formData });

    setFile(null);
  };

  const queryClient = useQueryClient();

  const updatePictureMutation = useMutation({
    mutationFn: updatePicture,
    onSuccess: () => queryClient.invalidateQueries("getCurrentUser"),
  });

  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    resizeImage(file).then((resizedImage) => {
      setFile(resizedImage);
      convertBase64(resizedImage).then(setDisplayFile);
    });
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      {updatePictureMutation.isError ? (
        <Alert severity="error">
          Une erreur est survenue : {updatePictureMutation.error.message}
        </Alert>
      ) : null}

      {updatePictureMutation.isSuccess ? (
        <Alert severity="success">Modification effectuée avec succès.</Alert>
      ) : null}

      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12}>
          <Typography align="center">
            Photo : Vous pouvez ici insérer une photo ou un logo. Cette photo
            pourra être visible par vos uplines.
            <br />
            Choix de la photo: Si vous souhaitez mettre votre logo, nous vous
            préconisons d'utiliser l'affichage carré. Si vous souhaitez mettre
            une photo de vous, alors nous vous préconisons l'affichage bulle.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.imageContainer}>
            <FormControl sx={{ flexDirection: "row" }}>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="circularPicture"
                sx={{ flexDirection: "row" }}
                value={circular.toString()}
                onChange={(event) => setCircular(event.target.value === "true")}
              >
                <Box sx={{ textAlign: "center" }}>
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Arrondi"
                  />
                  <Avatar sx={{ width: 200, height: 200 }}>
                    {displayFile ? (
                      <img
                        alt="preview"
                        src={displayFile}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <AccountCircleIcon sx={{ fontSize: 200 }} />
                    )}
                  </Avatar>
                </Box>
                <Box sx={{ marginLeft: "30px", textAlign: "center" }}>
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Carré"
                  />
                  <Avatar sx={{ width: 200, height: 200 }} variant="rounded">
                    {displayFile ? (
                      <img
                        alt="preview"
                        src={displayFile}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <AccountBox sx={{ fontSize: 200 }} />
                    )}
                  </Avatar>
                </Box>

                <Button
                  startIcon={<CreateIcon />}
                  className={classes.pictureIcon}
                  component="label"
                >
                  <input type="file" hidden onChange={handleChangeImage} />
                </Button>
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "flex-end",
          }}
        >
          <LoadingButton
            loading={updatePictureMutation.isLoading}
            type="submit"
            variant="contained"
          >
            Modifier ma photo
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  pictureIcon: {
    "&:hover": {
      backgroundColor: "inherit",
    },
    "&:focus": {
      backgroundColor: "inherit",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
}));

AgentUserFormAvatar.propTypes = {
  user: PropTypes.object,
  isFetched: PropTypes.bool,
};

export default AgentUserFormAvatar;
